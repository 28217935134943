import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-different-service-type',
  templateUrl: './different-service-type.component.html',
  styleUrls: ['./different-service-type.component.scss']
})
export class DifferentServiceTypeComponent {

  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
