import { Injectable } from '@angular/core';
import { FormGroup, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { HttpErrorsService } from '../http-error/http-errors.service';

import { ErrorsContainer } from './models/errors-container';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  errors: ErrorsContainer;

  formGroup: FormGroup;

  constructor(private httpErrorsService: HttpErrorsService) {
    this.errors = new ErrorsContainer();
  }

  get isValid(): boolean {
    this.validateControls(this.formGroup);
    
    return this.formGroup.disabled || this.formGroup.valid;
  }

  init(formGroup: FormGroup): void {
    this.formGroup = formGroup;
  }

  controlHasError(formControlName: string | string[], errorKey?: string): boolean {
    let control = this.getControl(formControlName);

    if (errorKey !== undefined && errorKey !== null) {
      return control.touched && control.hasError(errorKey);
    }
    else {
      return control.touched && !control.valid;
    }
  }

  handleErrorResponse(errorResponse: HttpErrorResponse, routerParam?: string | string[], id?: number): void {
    this.httpErrorsService.handleError(errorResponse, (errors) => this.setErrors(errors), routerParam, id);
  }

  setErrors(errors: string | { [property: string]: string[] }): void {
    if (typeof errors !== 'string') {
      for (let fieldName in errors) {
        let control = this.getControl(fieldName);
        if (control !== undefined && control !== null) {
          control.setErrors({ invalid: true });

          if (this.errors[fieldName] === undefined || this.errors[fieldName] === null) {
            this.errors[fieldName] = [];
          }

          for (let i = 0; i < errors[fieldName].length; i++) {
            this.errors[fieldName].push(errors[fieldName][i]);
          }

          if (control.untouched) {
            control.markAsTouched();
          }
        }
        else {
          for (let i = 0; i < errors[fieldName].length; i++) {
            this.errors.general.push(errors[fieldName][i]);
          }
        }
      }
    }
    else {
      this.errors.general.push(errors);
    }
  }

  clearErrors(): void {
    this.errors = new ErrorsContainer();
  }

  clearControlErrors(formControlName: string | number | (string | number)[]): void {
    if (typeof formControlName === 'string') {
      this.errors[formControlName] = [];
    }
    else if (Array.isArray(formControlName) && typeof formControlName[0] === 'string') {
      this.errors[formControlName[0]] = [];
    }

    let control = this.getControl(formControlName);
    if (control !== null) {
      control.setErrors(null);
      control.updateValueAndValidity();
    }
  }

  /**
   * Search for FormArray by name and returns its length.
   *
   * If control is not found it will throw an error.
   *
   * If control is found but it is not of type FormArray will return 0.
   * @param formControlName
   */
  getLength(formControlName: string | number | (string | number)[]): number {
    let control = this.getControl(formControlName);
    if (control instanceof FormArray) {
      return control.length;
    }

    return 0;
  }

  /**
   * Search for AbstractControl by name and returns its value.
   *
   * If control is not found it will throw an error.
   *
   * If formControlName is not passed it will return the value of initial FormGroup.
   * @param formControlName
   */
  getValue(formControlName?: string | number | (string | number)[]): any {
    if (formControlName !== undefined && formControlName !== null && formControlName !== '') {
      let control = this.getControl(formControlName);

      return control.value;
    }
    else {
      return this.formGroup.value;
    }
  }

  addValue(formControlName: string | number | (string | number)[], value: any): void {
    let control = this.getControl(formControlName);
    if (control instanceof FormArray) {
      control.push(value);

      control.updateValueAndValidity();
    }
  }

  removeValue(formControlName: string | number | (string | number)[], index: number): void {
    let control = this.getControl(formControlName);
    if (control instanceof FormArray && index !== undefined && index !== null) {
      control.removeAt(index);

      control.updateValueAndValidity();
    }
  }

  /**
   * Update AbstractControl value.
   *
   * @param value
   * @param formControlName
   */
  updateValue(value: any, formControlName?: string | number | (string | number)[]): void {
    let control: AbstractControl = this.formGroup;
    if (formControlName !== undefined && formControlName !== null && formControlName !== '') {
      control = this.getControl(formControlName);
    }

    if (control instanceof FormArray && Array.isArray(value)) {
      for (let i = 0; i < control.length; i++) {
        control.removeAt(i);

        i--;
      }

      for (let i = 0; i < value.length; i++) {
        control.push(value[i]);
      }
    }
    else {
      control.patchValue(value);
    }

    control.updateValueAndValidity();
  }

  /**
   * This method will trigger control state to enable or disable.
   *
   * If formControlName is not passed it will update state of the initial FormGroup.
   * @param formControlName
   * @param shouldBeEnabled
   */
  updateState(shouldBeEnabled: boolean, formControlName?: string | number | (string | number)[]): void {
    let control: AbstractControl = this.formGroup;
    if (formControlName !== undefined && formControlName !== null && formControlName !== '') {
      control = this.getControl(formControlName);
    }

    if (shouldBeEnabled) {
      control.enable();
    }
    else {
      control.markAsUntouched();

      control.disable();
    }

    control.updateValueAndValidity();
  }

  reset(): void {
    if (this.formGroup !== undefined && this.formGroup !== null) {
      this.formGroup.reset();
    }
  }

  dispose(): void {
    this.errors = new ErrorsContainer();

    this.reset();
  }

  private getControl(formControlName: string | number | (string | number)[], formGroup: AbstractControl = this.formGroup): AbstractControl {
    let result = this.tryGetControl(formControlName, formGroup);

    if (!result.isFound || result.control === null) {
      let controlName: string;
      if (Array.isArray(formControlName)) {
        controlName = formControlName.join(" > ");
      }
      else {
        controlName = formControlName.toString();
      }

      throw new Error("Cannot find control '" + controlName + "'.");
    }

    return result.control;
  }

  private tryGetControl(formControlName: string | number | (string | number)[], formGroup: AbstractControl = this.formGroup): { isFound: boolean, control: AbstractControl | null } {
    let control: AbstractControl | null;
    if (Array.isArray(formControlName)) {
      control = formGroup;
      formControlName.forEach(value => {
        if (control !== null) {
          control = this.tryGetControl(value, control).control;
        }
      });
    }
    else if (typeof formControlName === 'number') {
      if (formGroup instanceof FormArray) {
        control = (formGroup as FormArray).at(formControlName);
      }
      else {
        control = null;
      }
    }
    else {
      control = formGroup.get(formControlName);
    }

    if (control === undefined || control === null) {
      return {
        isFound: false,
        control: null
      };
    }

    return {
      isFound: true,
      control: control
    };
  }

  private validateControls(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach(field => {
      let control = formGroup.get(field);

      if (control instanceof FormControl) {
        if (control.enabled) {
          control.markAsTouched();

          control.updateValueAndValidity();
        }
      }
      else {
        if (control instanceof FormGroup) {
          this.validateControls(control as FormGroup);
        }
        else {
          this.validateControls(control as FormArray);
        }
      }
    });
  }
}
