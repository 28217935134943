import { Injectable } from '@angular/core';

import { TokenService } from 'src/app/services/shared-services/other-services/token/token.service';

import { TokenDataModel } from 'src/app/services/shared-services/other-services/token/models/token-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private tokenService: TokenService) { }

  isAuthenticated(): boolean {
    let token = this.tokenService.getToken();
    if (token === undefined || token === null || token === '') {
      return false;
    }

    if (this.isTokenExpired(this.tokenService.getTokenDataObject())) {
      return false;
    }

    return true;
  }

  isAdmin(): boolean {
    return true;

    let token = this.tokenService.getToken();
    if (token === undefined || token === null || token === '') {
      return false;
    }

    return true;
  }

  private isTokenExpired(tokenData: TokenDataModel): boolean {
    let expirationDate = new Date(0).setUTCSeconds(tokenData.exp);

    return !(expirationDate.valueOf() > new Date().valueOf());
  }
}
