import { Injectable } from '@angular/core';

import { MDBSelectOptionModel } from './models/mdb-select-option.model';
import { Choices } from 'src/app/services/user-services/http-services/questions/models/options-models/choices.model';

@Injectable({
  providedIn: 'root'
})
export class SelectValuesBuilderService {
  constructor() { }

  parseToMDBSelectOptions(choices: any[], propName?: string): MDBSelectOptionModel[] {
    
    if (choices !== undefined && choices.length !== 0 && !(choices[0] instanceof MDBSelectOptionModel)) {
      let options = [];

      choices.forEach((choice) => {
        let model = new MDBSelectOptionModel();

        if (choice.isoName !== undefined && choice.isoName !== null) {
          model.label = choice.isoName;
          model.value = choice[propName];
          model.disabled = choice.disabled;
        }else {
          model.label = choice.name !== undefined ? choice.name : choice.label;
          model.value = choice.value;
          model.disabled = choice.disabled;

          if(choice.selected !== undefined){
            model.selected = choice.selected;
          }

          if(choice.group !== undefined){
            model.group = choice.group;
          }
        }
        options = [...options, model]
      });
      return options;
    }
    
    return choices;
  }

  parseToMDBSelectGroupOptions(choices: any[], propName?: string): any[] {
    let selectedGroup;

    if (choices !== undefined && choices.length !== 0 && !(choices[0] instanceof MDBSelectOptionModel)) {
      let groups = [];
      let options = [];
      let zones = {};

      choices.forEach((choice, key, arr) => {
        let groupName = choice.group.name;

        if(selectedGroup === undefined) {
          selectedGroup = groupName;
          zones = {name: groupName}
        }else {
          if(groupName !== selectedGroup) {
            selectedGroup = groupName;
            zones = {...zones, options};
            groups = [...groups, zones]

            //Reset the zone object for the next zone
            zones = {name: groupName};
            options = [];
          }
        }

        let model = new MDBSelectOptionModel();

        model.label = choice.name !== undefined ? choice.name : choice.label;
        model.value = choice.value;
        model.disabled = choice.disabled;
        model.group = choice.group;
        
        options = [...options, model]

        if (Object.is(arr.length - 1, key)) {
          zones = {...zones, options};
          groups = [...groups, zones]
        }
      });
     
      return groups;
    }
    
    return choices;
  }

  parseMDBSelectOptionToChoiceModel(selectedOption: MDBSelectOptionModel): Choices {
    let model = new Choices();
    model.name = selectedOption.label;
    model.value = selectedOption.value;

    return model;
  }
}
