import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

import { timer } from 'rxjs';

import { CompletedQuestion } from 'src/app/services/user-services/http-services/questions/models/completed-question.model';

@Directive({
  selector: '[appMouseWheel]'
})
export class MouseWheelDirective {
  @Input() questions: CompletedQuestion[];
  @Output() updateIndexes: EventEmitter<any> = new EventEmitter<any>();

  Ttimer;
  isMouseWheelInRogress;
  scrollUp;

  @HostListener('wheel', ['$event']) onMouseScroll(e) {
    let currentIndex = this.questions.findIndex((question: CompletedQuestion) => question.isCurrent === true);

    if (
      !this.isMouseWheelInRogress ||
      ((!this.scrollUp && e.deltaY < 0) || (this.scrollUp && e.deltaY > 0))
    ) {
      if (this.Ttimer) {
        this.Ttimer.unsubscribe();
      }
      this.Ttimer = timer(500).subscribe(() => {
        this.isMouseWheelInRogress = false;
      });

      if (e.deltaY < 0) {
        this.scrollUp = true;
        if (currentIndex > -1) {
          if (currentIndex > 0) {
            this.questions[currentIndex - 1].isCurrent = true;
            this.questions[currentIndex].isCurrent = false;

            let prevIndex = null;
            if (this.questions[currentIndex - 2]) {
              prevIndex = currentIndex - 2;
            }

            this.updateIndexes.next({ prevQuestionIndex: prevIndex, nextQuestionIndex: currentIndex, isDirectionUp: true });
          }
        }

      } else if (e.deltaY > 0) {
        this.scrollUp = false;

        if (currentIndex > -1) {
          if (currentIndex !== this.questions.length - 1) {
            this.questions[currentIndex + 1].isCurrent = true;
            this.questions[currentIndex].isCurrent = false;
          }

          let nextIndex = null;
          if (this.questions[currentIndex + 2]) {
            nextIndex = currentIndex + 2;
          }

          this.updateIndexes.next({ prevQuestionIndex: currentIndex, nextQuestionIndex: nextIndex, isDirectionUp: false })
     
        }
      }
    }
    this.isMouseWheelInRogress = true;
  }
}
