import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  file: any;
  shouldShowError: boolean = false;

  ngOnInit(): void {

  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile() {
    this.file = null;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator() {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.file.progress === 100) {
          clearInterval(progressInterval);
          this.uploadFilesSimulator();
        }
        else {
          this.file.progress += 5;
        }
      }, 100);
    }, 300);
  }

  /**
   * Convert Files list to normal array list
   * @param file (File)
   */
  prepareFilesList(files: any) {
    let isValidFile = this.validateFile(files[0]);
    if (isValidFile) {
      this.shouldShowError = false;
      files[0].progress = 0;
      this.file = files[0];
      this.uploadFilesSimulator();
    }
    else {
      this.shouldShowError = true;
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  validateFile(file: any): boolean {
    if (file.type.includes('image') || file.type === 'application/pdf') {
      return true;
    }

    return false;
  }

}
