import { Component } from '@angular/core';

import { OfflinePageTypes } from 'src/app/enums/offline-page-types';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';

@Component({
  selector: 'app-base-offline',
  templateUrl: './base-offline.component.html',
  styleUrls: ['./base-offline.component.scss']
})
export class BaseOfflineComponent {
  status = OfflinePageTypes;
  state: number;
  close: Subject<any>;

  constructor(public modalRef: MDBModalRef, private questionStore: QuestionStoreService) { 
    this.close = new Subject<any>();
  }

  onActionEvent(): void {
    this.questionStore.setEditMode(true);
    this.modalRef.hide();
    this.close.next();
  }
}
