<main>

  <section class="howitworks scrollify">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h2 class="wow fadeIn">How Sprintax Tax Preparation Works</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="item wow fadeIn">
            <div class="icon">
              <strong><i class="icon-add-user"></i></strong>
            </div>
            <p>Create an account</p>
          </div>
          <div class="item wow fadeIn" data-wow-delay="0.2s">
            <div class="icon">
              <strong><i class="icon-conversation"></i></strong>
            </div>
            <p>Answer a few simple questions</p>
          </div>
          <div class="item wow fadeIn" data-wow-delay="0.4s">
            <div class="icon">
              <strong><i class="icon-deductions"></i></strong>
            </div>
            <p>Sprintax checks deductions and treaty benefits</p>
          </div>
          <div class="item wow fadeIn" data-wow-delay="0.6s">
            <div class="icon">
              <strong><i class="icon-taxform"></i></strong>
            </div>
            <p>Sprintax prepares the tax form</p>
          </div>
          <div class="item wow fadeIn" data-wow-delay="0.8s">
            <div class="icon">
              <strong><i class="icon-send"></i></strong>
            </div>
            <p>Print forms and send to tax authorities</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="whatwedo scrollify">
    <div class="container-fluid">
      <div class="row title">
        <div class="col">
          <h2 class="wow fadeIn">What Does Sprintax Do?</h2>
        </div>
      </div>
      <div class="row photo">
        <div class="col">
          <ul class="wow fadeIn" data-wow-delay="0.2s">
            <li>Nonresident 1040NR federal and state tax return preparation</li>
            <li>65 international tax treaty agreements and exemptions</li>
            <li>Maximum legal tax refund guaranteed</li>
            <li>100% US tax compliancy guaranteed</li>
          </ul>
          <div class="prepare-box wow fadeIn" data-wow-delay="0.4s">
            <h4>Prepare your US Taxes</h4>
            <p>from <strong>$29.95</strong></p>
            <p>Sprintax has helped 100,000 students, scholars, J1 workers, interns, trainees, professionals, au pairs
              and councillors prepare US tax returns.</p>
            <p class="text-center">
              <a href="#" class="btn btn-primary">Help me with my US taxes</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="compliance scrollify">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h2 class="wow fadeIn">100% Compliance Guaranteed.<br>Fully Guided US TAX Preparation</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ul>
            <li class="wow fadeIn">Sprinrax is the only online system for nonresident federal and state tax returns</li>
            <li class="wow fadeIn" data-wow-delay="0.2s">24 hr live chat with VITA Qualified Chat Agents-we’re always
              available, wherever you are!</li>
            <li class="wow fadeIn" data-wow-delay="0.4s">Prepare tax returns in minutes in an affordable, guided process
            </li>
            <li class="wow fadeIn" data-wow-delay="0.6s">Authorised Nonresident Partner for TurboTax</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <section class="testimonials scrollify">
    <div class="container-fluid">
      <div class="row slider videos justify-content-center">
        <div class="col-sm-4">
          <video width="400" height="300" controls class="wow fadeIn">
            <source src="../assets/vids/Flor_480p.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-sm-4">
          <video width="400" height="300" controls class="wow fadeIn" data-wow-delay="0.2s">
            <source src="../assets/vids/Presi_480p.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>

        <div class="col-sm-4">
          <video width="400" height="300" controls class="wow fadeIn" data-wow-delay="0.4s">
            <source src="../assets/vids/Vladi_480p.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </section>

</main>