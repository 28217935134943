import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-massachusetts-review-three',
  templateUrl: './massachusetts-review-three.component.html',
  styleUrls: ['./massachusetts-review-three.component.scss']
})
export class MassachusettsReviewThreeComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
