import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-not-supported-visa-offline',
  templateUrl: './not-supported-visa-offline.component.html',
  styleUrls: ['./not-supported-visa-offline.component.scss']
})
export class NotSupportedVisaOfflineComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
