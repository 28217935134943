import { Component } from '@angular/core';
import { FinishStateTypes } from 'src/app/enums/finish-state-types.enum';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-finish',
  templateUrl: './base-finish.component.html',
  styleUrls: ['./base-finish.component.scss']
})
export class BaseFinishComponent {
  status = FinishStateTypes;
  state: number;
  close: Subject<any>;

  constructor(public modalRef: MDBModalRef) { 
    this.close = new Subject<any>();
  }

  onActionEvent(): void {
    this.modalRef.hide();
    this.close.next();
  }

}
