import { NgModule } from '@angular/core';

import {
  DatepickerModule,
  InputUtilitiesModule,
  SelectModule,
  InputsModule,
  DropdownModule,
  CheckboxModule,
  TooltipModule,
  AccordionModule,
  CarouselModule,
  MdbSelectModule,
  ModalModule,
  IconsModule ,
  ButtonsModule,
  LightBoxModule,
  TabsModule
} from 'ng-uikit-pro-standard';

@NgModule({
  exports: [
    DatepickerModule,
    InputUtilitiesModule,
    SelectModule,
    InputsModule,
    DropdownModule,
    CheckboxModule,
    TooltipModule,
    AccordionModule,
    CarouselModule,
    ModalModule,
    IconsModule,
    MdbSelectModule,
    ButtonsModule,
    LightBoxModule,
    TabsModule
  ]
})
export class MDBootstrapModule { }
