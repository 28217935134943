import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { compareValidator } from '../../../modules/directives/directives/compare-validator.directive';

import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';
import { FormValidationService } from 'src/app/services/shared-services/other-services/form-validation/form-validation.service';

import { ResetPassword } from 'src/app/services/shared-services/http-services/account/models/reset-password.model';

export interface ResetPasswordData {
  code: string;
  userId: string;
}

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss']
})
export class ResetPasswordDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(public formValidationService: FormValidationService, private formBuilder: FormBuilder, public accountService: AccountService, @Inject(MAT_DIALOG_DATA) private data: ResetPasswordData) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.formValidationService.init(this.generateResetPasswordForm());
  }

  onResetPasswordButtonClicked(): void {
    if (this.formValidationService.isValid) {
      let resetPasswordDto: ResetPassword = this.formValidationService.getValue();

      this.subscription.add(this.accountService.resetPassword(resetPasswordDto).subscribe(() => {
        this.accountService.setSuccessfullMessage('You password has been changed succesfully.')
      }, (errorResponse: HttpErrorResponse) => {
        this.accountService.handleHttpError(errorResponse);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.formValidationService.dispose;

    this.accountService.clearError();
  }

  generateResetPasswordForm(): FormGroup {
    let codeFormControl = this.formBuilder.control(this.data.code);

    let userIdFormControl = this.formBuilder.control(this.data.userId);

    let passwordFormControl = this.formBuilder.control(null, Validators.required);

    let confirmPasswordFormControl = this.formBuilder.control(null, [
      Validators.required,
      compareValidator('password')
    ]);

    return this.formBuilder.group({
      code: codeFormControl,
      userId: userIdFormControl,
      password: passwordFormControl,
      confirmPassword: confirmPasswordFormControl
    }, { updateOn: 'blur' });
  }

}
