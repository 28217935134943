import { NgModule } from '@angular/core';

import { SortPipe } from './pipes/sort.pipe';
import { NumberEnumKeyPipePipe } from './pipes/number-enum-key-pipe.pipe';


@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    SortPipe,
    NumberEnumKeyPipePipe
  ],
  exports: [
    SortPipe,
    NumberEnumKeyPipePipe
  ]
})
export class PipesModule { }
