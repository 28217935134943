import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';
import { FormValidationService } from 'src/app/services/shared-services/other-services/form-validation/form-validation.service';

import { SentPasswordResetLink } from 'src/app/services/shared-services/http-services/account/models/send-password-reset-link.model';

import { EMAIL_PATTERN } from 'src/app/consts/consts';
import { FormFactoryService } from 'src/app/services/helpers-services/form-factory/form-factory.service';
import { ForgottenPasswordForm } from 'src/app/services/helpers-services/form-factory/utilities/classes/forgotten-password-form';
import { AbstractForm } from 'src/app/services/helpers-services/form-factory/utilities/interfaces/abstract-form.interface';
import { EmailData } from 'src/app/services/helpers-services/form-factory/utilities/interfaces/email-data.interface';


@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  providers: [{ provide: FormFactoryService, useClass: ForgottenPasswordForm }]
})
export class ForgotPasswordDialogComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  forgottenPasswordForm: AbstractForm;
  constructor(public formValidationService: FormValidationService, public accountService: AccountService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: EmailData) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.forgottenPasswordForm = new ForgottenPasswordForm(this.formBuilder, this.data);
    this.formValidationService.init(this.forgottenPasswordForm.generateForm());
  }

  onResetPasswordButtonClicked(): void {
    if (this.formValidationService.isValid) {
      let model: SentPasswordResetLink = this.formValidationService.getValue();

      this.subscription.add(this.accountService.sendPasswordResetLink(model).subscribe(() => {
        this.accountService.setSuccessfullMessage('Reset password link has been sent. Check you email for more details.');
      }, (errorResponse: HttpErrorResponse) => {
        this.accountService.handleHttpError(errorResponse);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.formValidationService.dispose();

    this.accountService.clearError();
  }

  generateForgotPasswordForm(): FormGroup {
    let emailFormControl = this.formBuilder.control(
      this.data.email,
      [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN)
      ]
    );

    return this.formBuilder.group({
      email: emailFormControl
    }, { updateOn: 'blur' })
  }
}
