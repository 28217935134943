import { Injectable } from '@angular/core';

import { Base64 } from 'js-base64';

import { TokenDataModel } from './models/token-data.model';

import { LOCAL_STORAGE_TOKEN_KEY } from 'src/app/consts/consts';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  getToken(): string | null | undefined {
    let localStorageToken: string | null | undefined = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (localStorageToken !== null && localStorage !== undefined) {
      return localStorageToken;
    }

    return null;
  }

  getTokenDataObject(): TokenDataModel | null {
    let token = this.getToken();
    if (token === undefined || token === null || token === '') {
      return null;
    }

    let tokenData = token.split('.')[1];

    let decodedTokenData = Base64.decode(tokenData);

    return <TokenDataModel>JSON.parse(decodedTokenData);
  }

  setToken(token: string): void {
    this.removeToken();

    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  }

  removeToken(): void {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  }
}
