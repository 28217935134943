<h1>Reset password</h1>
<div *ngIf="accountService.hasError" class="form-group text-danger">
  {{accountService.errorMessage}}
</div>
<div *ngIf="accountService.successfullMessage" class="alert-success mb-2 response-message p-2">
  {{accountService.successfullMessage}}
</div>
<form (ngSubmit)="onResetPasswordButtonClicked()" [formGroup]="formValidationService.formGroup" novalidate>
  <div class="form-field">
    <mat-form-field class="example-full-width">
      <input matInput type="password" placeholder="Password" formControlName="password">
      <mat-error class="form-field-error" *ngIf="formValidationService.controlHasError('password', 'required')">
        Password is required
      </mat-error>
    </mat-form-field>
  </div>
  <div class="form-field">
    <mat-form-field class="example-full-width">
      <input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword">
      <mat-error class="form-field-error" *ngIf="formValidationService.controlHasError('confirmPassword', 'required')">
        Password confirmation is required
      </mat-error>
      <mat-error class="form-field-error" *ngIf="formValidationService.controlHasError('confirmPassword', 'compare')">
        Passwords mismatch
      </mat-error>
    </mat-form-field>
  </div>
</form>
<div class="form-element row">
  <div class="col text-center">
    <button class="btn btn-primary btn-block my-4" (click)="onResetPasswordButtonClicked()">Reset my password</button>
  </div>
</div>
