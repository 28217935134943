import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { TokenService } from '../token/token.service';

import { ErrorCodeMessages } from 'src/app/enums/error-code-messages';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorsService {
  constructor(private tokenService: TokenService, private routerService: Router, private location: Location, private dialogRef: MatDialog) {
  }

  handleError(httpErrorResponse: HttpErrorResponse, errorMessageHandler: (error: string | { [property: string]: string[] }) => void, routerParam?: string | string[], id?: number): void {
    switch (httpErrorResponse.status) {
      case 0:
      case 415:
        errorMessageHandler(ErrorCodeMessages.Error415Message);
        break;

      case 401:
      case 403:
        this.tokenService.removeToken();

        this.dialogRef.closeAll();

        this.routerService.navigate(['/']);

        break;

      case 404:
        if (id !== undefined && id !== null) {
          let path = this.location.path();
          let url = path.substring(0, path.indexOf(id.toString()) - 1);

          this.routerService.navigateByUrl(url);
        }
        else {
          errorMessageHandler(httpErrorResponse.error);
        }

        break;

      case 430:
        errorMessageHandler(ErrorCodeMessages.Error430Message);
        break;

      case 433:
        errorMessageHandler(ErrorCodeMessages.Error433Message);
        break;

      case 434:
        errorMessageHandler(ErrorCodeMessages.Error434Message)
        break;

      case 500:
      case 501:
      case 503: {
        errorMessageHandler(ErrorCodeMessages.Error500Message)
        this.dialogRef.closeAll();

        this.routerService.navigate(['/', 'server-error'], { state: { message: httpErrorResponse.error.Error } });
      }

      default:
        errorMessageHandler(httpErrorResponse.error);
        break;
    }
  }
}
