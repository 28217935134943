export class MDBSelectOptionModel {
  value: string;
  label: string;
  disabled?: boolean;
  group?: boolean;
  selected?: boolean

  constructor() {
  }
}
