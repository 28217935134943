import { HttpErrorResponse } from '@angular/common/http';
import { Component, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { PdfService } from '../../final-screens/services/pdf/pdf.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-income-document',
  templateUrl: './income-document.component.html',
  styleUrls: ['./income-document.component.scss']
})
export class IncomeDocumentComponent {
  @Output() action: EventEmitter<void>;

  private subscription: Subscription;
  pdfs: any;
  
  constructor(private pdfService: PdfService) { 
    this.action = new EventEmitter<void>();
    this.subscription = new Subscription();
  }

  onFederalPDFsButtonClicked(): void {
    this.subscription.add(this.pdfService.getAllTdsFormsPDFs().subscribe((response) => {
      this.pdfs = response;
    }, (errorResponse: HttpErrorResponse) => {
      console.log('Request failed');
      console.log(errorResponse.message);
    }, () => {
      console.log('Observable finished...');
      saveAs(this.pdfs, new Date().getTime() + '.pdf');
    }));
  }

  submit(): void {
    this.action.next();
  }
}
