import { Component, OnInit, EventEmitter, Output } from '@angular/core';

import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';

@Component({
  selector: 'app-about-you',
  templateUrl: './about-you.component.html',
  styleUrls: ['./about-you.component.scss']
})
export class AboutYouComponent implements OnInit {
  @Output() action: EventEmitter<void>;
  taxYear: number;
  constructor(private questionStoreService: QuestionStoreService) { 
    this.action = new EventEmitter<void>();
  }

  ngOnInit(): void {
    this.taxYear = this.questionStoreService.getSelectedYear();
  }

  submit(): void {
    this.action.next();
  }
}
