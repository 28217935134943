<footer class="scrollify">
  <div class="container-fluid">
    <div class="row links wow fadeIn">
      <div class="col">
        <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">What we do</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Terms & conditions</a></li>
            <li><a href="#">Policies</a></li>
            <li><a href="#">FAQ</a></li>
            <li><a href="#">Contact</a></li>
        </ul>
      </div>
    </div>
    <div class="row awards wow fadeIn" data-wow-delay="0.2s">
      <div class="col">
        <a target="_blank" href="http://www.bbb.org/chicago/business-reviews/tax-return-preparation/taxback-in-chicago-il-88004090"></a>
        <a target="_blank" href="https://www.taxback.com/en/awards/"></a>
        <a target="_blank" href="https://www.taxback.com/en/awards/"></a>
        <a target="_blank" href="https://www.taxback.com/en/awards/"></a>
        <a target="_blank" href="https://www.taxback.com/en/awards/"></a>
        <a target="_blank" href="http://www.alliance-exchange.org/about-alliance/"></a>
        <a target="_blank" href="https://www.taxback.com/en/awards/"></a>
        <a rel="nofollow" href="javascript:void(0);"></a>
        <a target="_blank" href="https://www.wysetc.org/"></a>
      </div>
    </div>
    <div class="row social wow fadeIn" data-wow-delay="0.4s">
      <div class="col">
        <a href="https://www.facebook.com/sprintaxcom" target="_blank">Facebook</a>
        <a href="https://twitter.com/Sprintax_USA" target="_blank">Twitter</a>
        <a href="https://www.linkedin.com/company/sprintax?trk=tyah" target="_blank">Linkedin</a>
        <a href="#" target="_blank">Instagram</a>
        <a href="#" target="_blank">Blog</a>
      </div>
    </div>
    <div class="row copy wow fadeIn" data-wow-delay="0.6s">
      <div class="col">
        www.sprintax.com &copy; All rights reserved
      </div>
    </div>
  </div>
</footer>
