import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';
import { TokenService } from 'src/app/services/shared-services/other-services/token/token.service';

import { TokenModel } from 'src/app/services/shared-services/other-services/token/models/token.model';
import { Login } from 'src/app/services/shared-services/http-services/account/models/login.model';
import { ResendEmail } from 'src/app/services/shared-services/http-services/account/models/resend-email.model';

import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

import { FormFactoryService } from 'src/app/services/helpers-services/form-factory/form-factory.service';
import { LoginForm } from 'src/app/services/helpers-services/form-factory/utilities/classes/login-form';
import { AbstractForm } from 'src/app/services/helpers-services/form-factory/utilities/interfaces/abstract-form.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    { provide: FormFactoryService, useClass: LoginForm }
  ] //Separate instance for login form, because register and login are placed in common component
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output('close') close: EventEmitter<boolean>;
  loginForm: AbstractForm;
  formGroup: FormGroup;

  private subscription: Subscription;

  constructor(public accountService: AccountService, private formBuilder: FormBuilder, private router: Router, private tokenService: TokenService, private dialog: MatDialog) {
    this.close = new EventEmitter<boolean>();
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.loginForm = new LoginForm(this.formBuilder);
    this.formGroup = this.loginForm.generateForm();
    this.formGroup.updateValueAndValidity({onlySelf: true});
  }

  onSubmit(): void {
    if (this.formGroup.valid) {

      let loginModelDto: Login = this.formGroup.value;

      this.subscription.add(this.accountService.login(loginModelDto)
        .subscribe((response: TokenModel) => {
          this.tokenService.setToken(response.token);

          let theme = localStorage.getItem('theme');

          document.body.classList.add(theme);

          this.router.navigate(['user']);

          this.close.next(true);
        }, (error: HttpErrorResponse) => {
          this.accountService.handleHttpError(error);
          if (error.status === 430) {
            let model = new ResendEmail();
            model.email = this.formGroup.get('email').value;
            this.accountService.resendEmail(model).subscribe(() => {
              console.log('Email was sent')
            });
          }
        }
        ));
    }
  }

  onCreateAccountButtonClicked(): void {
    this.formGroup.reset();

    this.accountService.clearError();
  }

  onForgottenPasswordButtonClicked(): void {
    let email = this.formGroup.get('email').value;
    this.dialog.closeAll();

    this.dialog.open(ForgotPasswordDialogComponent, {
      width: '980px',
      data: { email: email }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    
    this.accountService.clearError();
  }
}
