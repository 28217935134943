import { Component, OnInit, HostListener } from '@angular/core';


import { MatDialog } from '@angular/material/dialog';

import { EntryDialogComponent } from '../entry-dialog/entry-dialog.component';
import { ConfigDialogComponent } from '../config-dialog/config-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  dialogRef;
  isSticky: boolean;
  isMobileNavVisible: boolean;

  constructor(public dialog: MatDialog) {
    this.isSticky = false;
    this.isMobileNavVisible = false;
  }

  ngOnInit(): void { }

  onOpenDialogButtonClicked(focusLogin: boolean): void {
    if (focusLogin === true) {
      this.dialogRef = this.dialog.open(EntryDialogComponent, {
        data: { focusLogin: true },
        panelClass: 'loginPanel'
      });
    }
    else {
      this.dialogRef = this.dialog.open(EntryDialogComponent, {
        data: { focusLogin: false },
        panelClass: 'createPanel'
      });
    }

    this.dialogRef.afterClosed().subscribe(() => {
      // console.log('The dialog was closed');
    });
  }

  openConfigDialog(): void {
    this.dialog.open(ConfigDialogComponent, {
      autoFocus: false,
      width: '300px',
      panelClass: 'config-dialog'
    });
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 100;
  }

  showMobileNav(): void {
    this.isMobileNavVisible = true;
  }

  hideMobileNav(): void {
    this.isMobileNavVisible = false;
  }
}
