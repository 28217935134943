import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from 'src/app/services/shared-services/other-services/auth/auth.service';
import { EnvironmentStoreService } from 'src/app/services/shared-services/other-services/store/environment-store.service';
import { TokenService } from 'src/app/services/shared-services/other-services/token/token.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private environmentStore: EnvironmentStoreService, private tokenService: TokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const isLoggedIn = this.authenticationService.isAuthenticated();
    const isApiUrl = request.url.startsWith(this.environmentStore.getBaseUrl().protocol + '://' + this.environmentStore.getBaseUrl().url);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.tokenService.getToken()}`
        }
      });
    }

    return next.handle(request);
  }
}
