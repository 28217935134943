import { Injectable } from '@angular/core';

import { SelectValuesBuilderService } from 'src/app/services/helpers-services/select-parser/select-values-builder.service';

import { Country } from '../../http-services/countries/models/country.model';
import { MDBSelectOptionModel } from 'src/app/services/helpers-services/select-parser/models/mdb-select-option.model';

@Injectable({
  providedIn: 'root'
})
export class CountryStoreService {
  private countries: Country[];

  constructor(private selectValuesBuilderService: SelectValuesBuilderService) { }

  setCountries(countries: Country[]): void {
    this.countries = countries;
  }

  getCountries(): Country[] {
    return this.countries;
  }

  getAsMDBSelectOptions(prop?: string): MDBSelectOptionModel[] {
    return this.selectValuesBuilderService.parseToMDBSelectOptions(this.countries, prop);
  }

  isEmpty(): boolean {
    if (this.countries !== null && this.countries !== undefined && this.countries.length > 0) {
      return false
    }
    else {
      return true;
    }
  }
}
