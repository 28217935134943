import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-living-in-us-finish',
  templateUrl: './living-in-us-finish.component.html',
  styleUrls: ['./living-in-us-finish.component.scss']
})
export class LivingInUsFinishComponent {
  @Output() action: EventEmitter<void>;
  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
