import { Component, OnInit, HostListener } from "@angular/core";

import { SideBarService } from "./helpers/sidebar.service";

@Component({
  selector: "app-mobile-header",
  templateUrl: "./mobile-header.component.html",
  styleUrls: ["./mobile-header.component.scss"]
})
export class MobileHeaderComponent implements OnInit {
  hamburgerArrow: boolean = false;
  constructor(private sideBarService: SideBarService) { }
  ngOnInit(): void { }

  expand() {
    this.sideBarService.toggle();
  }

  clickHamburger() {
    this.hamburgerArrow = !this.hamburgerArrow;
  }
}
