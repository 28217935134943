import { AbstractForm } from '../interfaces/abstract-form.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EMAIL_PATTERN } from 'src/app/consts/consts';
import { EmailData } from '../interfaces/email-data.interface';
import { Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable()
export class ForgottenPasswordForm implements AbstractForm {

    constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: EmailData) {

    }

    generateForm(): FormGroup {
        let emailFormControl = this.formBuilder.control(
            this.data.email,
            [
                Validators.required,
                Validators.pattern(EMAIL_PATTERN)
            ]
        );

        return this.formBuilder.group({
            email: emailFormControl
        }, { updateOn: 'blur' })
    }
}