import { Injectable } from '@angular/core';
import { AbstractForm } from './utilities/interfaces/abstract-form.interface';


@Injectable({
  providedIn: 'root'
})
export abstract class FormFactoryService {

  constructor() { }

  protected abstract createForm(): AbstractForm;

  public getForm(): AbstractForm {
    return this.createForm();
  }
}
