import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-server-errors',
  templateUrl: './server-errors.component.html',
  styleUrls: ['./server-errors.component.scss']
})
export class ServerErrorsComponent implements OnInit {

  message: string;

  constructor(private router: Router) {
    this.message = this.router.getCurrentNavigation().extras.state.message;
  }

  ngOnInit() {
  }

}
