import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-massachusetts-review-two',
  templateUrl: './massachusetts-review-two.component.html',
  styleUrls: ['./massachusetts-review-two.component.scss']
})
export class MassachusettsReviewTwoComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
