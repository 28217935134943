import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { QuestionStoreService } from 'src/app/services/user-services/other-services/question-store.service';

import { StacyMessagesModel } from './models/stacy-messages.model';

import { THEME_KEY, DEFAULT_THEME, STACY_NAV_BUTTON_MORE, STACY_NAV_BUTTON_PREV } from 'src/app/consts/consts';

const DOC_CONTAINER_CLASS = 'doc doc--';
const IMG_CONTAINER_CLASS = 'img__container  ';
const IMG_SOURCE = '../../../../../../../../../assets/img/forms/pdoc_';

@Component({
  selector: 'app-stacy',
  templateUrl: './stacy.component.html',
  styleUrls: ['./stacy.component.scss']
})
export class StacyComponent implements OnInit {
  @ViewChild('stacy') videoplayer: ElementRef;

  displayAvatar: boolean;
  isDefaultStacy: boolean;
  stacy: StacyMessagesModel;
  source: string;
  activeMessage: string;
  navigationButtonMessage: string;
  shouldShowDocument: boolean;
  showNavBtn: boolean;
  timeOut: any;
  documentContainerClass: string;
  imageContainerClass: string;
  imageSource: string;
  lightBoxImages: any[] = [];

  constructor(private questionStore: QuestionStoreService) {
    this.displayAvatar = true;
    this.isDefaultStacy = true;
    this.shouldShowDocument = false;
    this.navigationButtonMessage = STACY_NAV_BUTTON_MORE;
  }

  ngOnInit(): void {
    this.questionStore.castStacy$.subscribe(stacy => {
      if (stacy !== null && stacy !== undefined) {
      if (stacy.instantMessage !== null && stacy.instantMessage !== '' && stacy.instantMessage !== undefined) {
        this.navigationButtonMessage = STACY_NAV_BUTTON_MORE;

        this.showNavBtn = false;

        if (this.timeOut !== undefined && this.timeOut !== null) {
          clearTimeout(this.timeOut);
        }

        this.isDefaultStacy = false;
        this.stacy = stacy;

        this.activeMessage = stacy.instantMessage;

        if (stacy.additionalMessage !== '' && stacy.additionalMessage) {
          this.showNavBtn = true;
          this.timeOut = setTimeout(() => {
            this.activeMessage = stacy.additionalMessage;

            this.navigationButtonMessage = STACY_NAV_BUTTON_PREV;
          }, stacy.additionalMessageTimeout)
        }
      }
      else {
        if (stacy.document !== null && stacy.document !== undefined) {
          this.shouldShowDocument = true;
          this.isDefaultStacy = false;
          this.documentContainerClass = DOC_CONTAINER_CLASS + stacy.document.docType;
          this.imageContainerClass = IMG_CONTAINER_CLASS + stacy.document.field;
          this.imageSource = IMG_SOURCE + stacy.document.docType + '.gif';

          this.lightBoxImages = [];
          this.lightBoxImages.push({ img: this.imageSource, thumb: this.imageSource });
          this.stacy = stacy;
        }
        else {
          this.activeMessage = 'I will help you with complicated questions';
        }
      }
    }
    });
  }

  onNavigationMessageButtonClicked(): void {
    if (this.navigationButtonMessage === STACY_NAV_BUTTON_MORE) {
      this.activeMessage = this.stacy.additionalMessage;
      this.navigationButtonMessage = STACY_NAV_BUTTON_PREV;
      clearTimeout(this.timeOut);
    }
    else {
      this.navigationButtonMessage = STACY_NAV_BUTTON_MORE;
      this.activeMessage = this.stacy.instantMessage;
    }
  }

  toggleAvatar(): void {
    this.displayAvatar = !this.displayAvatar;

    let theme = localStorage.getItem(THEME_KEY);

    if (theme !== '' && theme !== null && theme !== undefined) {
      this.source = '../../../../../../../assets/vids/stacy-' + theme + '.mp4';
    } else {
      this.source =
        '../../../../../../../assets/vids/stacy-' + DEFAULT_THEME + '.mp4';
    }

    this.videoplayer.nativeElement.src = this.source;

    if (this.displayAvatar) {
      this.videoplayer.nativeElement.load();
    }
  }
}
