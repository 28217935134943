import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BaseHttpService } from 'src/app/services/base-services/base-http.service';
import { HttpErrorsService } from 'src/app/services/shared-services/other-services/http-error/http-errors.service';
import { EnvironmentStoreService } from 'src/app/services/shared-services/other-services/store/environment-store.service';

import { Country, CountryAdapter } from './models/country.model';
import { CountryStoreService } from 'src/app/services/shared-services/other-services/store/country-store.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesService extends BaseHttpService {
  constructor(private httpClient: HttpClient, httpErrorsService: HttpErrorsService, environmentStore: EnvironmentStoreService, private adapter: CountryAdapter, private countriesStore: CountryStoreService) {
    super(httpErrorsService, environmentStore);
  }

  getCountries(): Observable<Country[]> {
    return this.httpClient.get<Country[]>(`${this.getBaseUrl()}api/v${this.getApiVersion()}/CommonValues/Countries`)
      .pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item))),
      tap((data) => {
        this.countriesStore.setCountries(data);
      }));
  }
}
