import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';
import { CountriesService } from 'src/app/services/shared-services/http-services/countries/countries.service';
import { FormFactoryService } from 'src/app/services/helpers-services/form-factory/form-factory.service';

import { Country } from 'src/app/services/shared-services/http-services/countries/models/country.model';
import { CreateAccount } from 'src/app/services/shared-services/http-services/account/models/create-account.model';
import { MDBSelectOptionModel } from 'src/app/services/helpers-services/select-parser/models/mdb-select-option.model';
import { RegisterForm } from 'src/app/services/helpers-services/form-factory/utilities/classes/register-form';

import { AbstractForm } from 'src/app/services/helpers-services/form-factory/utilities/interfaces/abstract-form.interface';
import { CountryStoreService } from 'src/app/services/shared-services/other-services/store/country-store.service';

const PHONE_CODE_VALUE_PROP = 'phoneCode';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [{ provide: FormFactoryService, useClass: RegisterForm }]
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Output('close') close: EventEmitter<boolean>;
  areTermsAccepted: boolean;
  isUserAgreeToIRC: boolean;

  registerForm: AbstractForm;
  formGroup: FormGroup;

  countries: MDBSelectOptionModel[];

  private subscription: Subscription;

  constructor(public accountService: AccountService, private formBuilder: FormBuilder, private countriesService: CountriesService, private countriesStore: CountryStoreService) {
    this.areTermsAccepted = false;
    this.isUserAgreeToIRC = false;

    this.close = new EventEmitter<boolean>();

    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.registerForm = new RegisterForm(this.formBuilder);
    this.formGroup = this.registerForm.generateForm();

    if (this.countriesStore.isEmpty()) {
      this.getCountries();
    }
    else {
      this.countries = this.countriesStore.getAsMDBSelectOptions(PHONE_CODE_VALUE_PROP);
    }
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      let createAccountModel: CreateAccount = this.formGroup.value;

      this.accountService.createAccount(createAccountModel)
        .subscribe(() => {
          this.accountService.setSuccessfullMessage('Successfully created account. You will receive an email from Sprintax. Please follow the link to activate your account.');
        }, (error: HttpErrorResponse) => {
          this.accountService.handleHttpError(error);
        });
    }
    else {
      this.formGroup.markAllAsTouched();
    }
  }

  onLoginButtonClicked(): void {
    this.formGroup.reset();

    this.accountService.clearError();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.formGroup.reset();

    this.accountService.clearError();
  }

  private getCountries(): void {
    this.subscription.add(this.countriesService.getCountries()
      .subscribe((countries: Country[]) => {
        this.countries = this.countriesStore.getAsMDBSelectOptions(PHONE_CODE_VALUE_PROP);
      }, (errorResponse: HttpErrorResponse) => {
        this.accountService.handleHttpError(errorResponse);
      })
    );
  }
}
