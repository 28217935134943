import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-no-us-income',
  templateUrl: './no-us-income.component.html',
  styleUrls: ['./no-us-income.component.scss']
})
export class NoUsIncomeComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
