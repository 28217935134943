<!-- <header>
  <a class="logo wow fadeInDown"></a>
  <a class="btn btn-mobile" (click)="expand()">Navigation</a>
</header> -->
<!-- <div class="btn-mobile" (click)="expand()">
  <div class="bar"></div>
  <div class="bar"></div>
  <div class="bar"></div>
</div> -->

<div class="hamburger" [ngClass]="{'hamburger--squeeze': hamburgerArrow}" (click)="clickHamburger();expand()">
  <div class="hamburger-inner"></div>
</div>