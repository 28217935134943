import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { AccountService } from 'src/app/services/shared-services/http-services/account/account.service';

import { ResendEmail } from 'src/app/services/shared-services/http-services/account/models/resend-email.model';

import { EntryDialogComponent } from '../entry-dialog/entry-dialog.component';

import { ENTRY_MODAL_SIZE } from 'src/app/consts/consts';


export interface UserData {
  shouldShowSuccess: boolean;
  email: string;
}

@Component({
  selector: 'app-email-confirmation-dialog',
  templateUrl: './email-confirmation-dialog.component.html',
  styleUrls: ['./email-confirmation-dialog.component.scss']
})
export class EmailConfirmationDialogComponent implements OnInit, OnDestroy {

  private email: ResendEmail;
  private subscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: UserData, private dialog: MatDialog, private accountService: AccountService) {
    this.email = new ResendEmail();

    this.subscription = new Subscription();
  }

  ngOnInit(): void {
  }

  onLoginButtonClicked(): void {
    this.dialog.closeAll();

    this.dialog.open(EntryDialogComponent ,{
      width: ENTRY_MODAL_SIZE,
      data: { focusLogin: true },
      panelClass: 'loginPanel'
    });
  }

  onResendButtonClicked(): void {
    this.subscription.add(this.accountService.resendEmail(this.email)
      .subscribe((response: string) => {
      }, (errorResponse: HttpErrorResponse) => {
        this.accountService.handleHttpError(errorResponse);
      }));
  }

  ngOnDestroy(): void {
    this.accountService.clearError();
  }
}
