import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { DirectivesModule } from 'src/app/modules/directives/directives.module';
import { PipesModule } from 'src/app/modules/pipes/pipes.module';
import { MaterialModule } from '../material/material.module';
import { MDBootstrapModule } from '../mdbootstrap/mdbootstrap.module';

import { StacyComponent } from './components/stacy/stacy.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileProgressComponent } from './components/file-progress/file-progress.component';

import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';

import { SideBarService } from './components/mobile-header/helpers/sidebar.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    DirectivesModule,
    PipesModule,
    MaterialModule,
    MDBootstrapModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  declarations: [
    StacyComponent,
    FileUploadComponent,
    FileProgressComponent,
    MobileHeaderComponent
  ],
  providers: [TranslateService, SideBarService],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PipesModule,
    MaterialModule,
    MDBootstrapModule,
    TranslateModule,
    BsDatepickerModule,
    StacyComponent,
    FileUploadComponent,
    FileProgressComponent,
    MobileHeaderComponent
  ]
})
export class SharedModule {}
