import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { EnvironmentStoreService } from 'src/app/services/shared-services/other-services/store/environment-store.service';

import { BaseUrlModel } from 'src/app/services/shared-services/other-services/store/models/base-url.model';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss']
})
export class ConfigDialogComponent implements OnInit {
  baseURLProtocol: string;
  baseURL: string;
  baseURLPort: number;
  baseApiVersion: number;
  currentVersion: string;

  constructor(private dialogRef: MatDialogRef<ConfigDialogComponent>, private environmentStore: EnvironmentStoreService) {
  }

  ngOnInit(): void {
    this.currentVersion = environment.version;

    let url = this.environmentStore.getBaseUrl();

    this.baseURLProtocol = url.protocol;
    this.baseURL = url.url;
    this.baseApiVersion = url.apiVersion;
    this.baseURLPort = url.port;
  }

  onSaveChangesButtonClicked(): void {
    let updatedUrl = new BaseUrlModel();
    updatedUrl.protocol = this.baseURLProtocol;
    updatedUrl.apiVersion = this.baseApiVersion;
    updatedUrl.url = this.baseURL;
    updatedUrl.port = this.baseURLPort;

    this.environmentStore.updateUrl(updatedUrl);
    
    this.dialogRef.close()
  }
}
