import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sin-number-offline',
  templateUrl: './sin-number-offline.component.html',
  styleUrls: ['./sin-number-offline.component.scss']
})
export class SinNumberOfflineComponent {
  @Output() action: EventEmitter<void>;

  constructor() { 
    this.action = new EventEmitter<void>();
  }

  submit(): void {
    this.action.next();
  }
}
