import { Injectable } from '@angular/core';

import { Adapter } from 'src/app/services/adapters/adapter.interface';

export class Country {
  constructor(
    public isoName: string,
    public iso2: string,
    public iso3: string,
    public phoneCode: string
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class CountryAdapter implements Adapter<Country> {

  adapt(item: any): Country {
    return new Country(
      item.isoName,
      item.iso2,
      item.iso3,
      item.phoneCode
    );
  }
}
