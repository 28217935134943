<header class="header" [ngClass]="{'sticky' : isSticky}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 col-md-7 col-xl-8">
        <a class="logo"></a>
        <div class="btn-group">
          <button class="btn btn-menu dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">Clients</button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">Tax Prep for International Students and Scholars</a>
            <a class="dropdown-item" href="#">J1 Work & Travel</a>
            <a class="dropdown-item" href="#">Internship</a>
            <a class="dropdown-item" href="#">Trainee</a>
            <a class="dropdown-item" href="#">H2B Temporary Workers</a>
            <a class="dropdown-item" href="#">Tax Prep for Foreign professionals</a>
            <a class="dropdown-item" href="#">Au Pairs</a>
            <a class="dropdown-item" href="#">Camp Counselors</a>
            <a class="dropdown-item" href="#">Sports Scholarships</a>
            <a class="dropdown-item" href="#">Royalties</a>
            <a class="dropdown-item" (click)="openConfigDialog()">Change base URL(dev purposes only!)</a>
          </div>
        </div>
        <div class="btn-group">
          <button class="btn btn-menu dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">Partners</button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">PARTNER</a>
            <a class="dropdown-item" href="#">TDS</a>
          </div>
        </div>
      </div>
      <div class="col-8 col-md-5 col-xl-4 buttons">
        <a href="javascript:;"
          class="btn btn-primary btn-create text-uppercase pt_5 pb_5 mr_15 d-none d-lg-inline-block"
          (click)="onOpenDialogButtonClicked(false)">Create My Account</a>
        <a (click)="onOpenDialogButtonClicked(true)" class="btn btn-enter">Log in</a>
        <a href="javascript:;" class="btn btn-mobile" (click)="showMobileNav()">Navigation</a>
      </div>
    </div>
  </div>
</header>

<section class="prelogin scrollify">
  <section class="hero">
    <div class="container-fluid">
      <div class="row titles wow fadeIn" data-wow-delay="0.3s">
        <div class="col-12 col-lg-9 col-xl-6">
          <h1>US Tax Preparation for Nonresidents</h1>
          <h2>The only online self-preparation software for nonresident federal and state tax returns</h2>
          <ul>
            <li>Provides an all-in-one solution covering both IRS and State forms, FICA returns and ITIN applications
            </li>
            <li>Offers automatic generation of completed tax return forms</li>
            <li>Guarantees a maximum legal tax refund for federal, state & medicare incl. tax treaty benefits</li>
            <li>Assists you trough the process via a 24/7 Live Chat, Social Media support, E-mail support, FAQ's and
              our
              virtual assistant Stacy</li>
          </ul>
        </div>
      </div>
      <div class="row prices wow fadeIn" data-wow-delay="0.5s">
        <div class="col">
          <p>FEDERAL from <span>$49.95</span></p>
          <p>STATE from <span>$29.95</span></p>
        </div>
      </div>
      <div class="row create animated fadeIn delay-700ms">
        <div class="col">
          <a href="javascript:;" class="btn btn-primary btn-create text-uppercase"
            (click)="onOpenDialogButtonClicked(false)">Create
            My
            Account</a>
        </div>
      </div>
    </div>
  </section>

  <section class="score">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-9 partners">
          <p>Tax partner of</p>
          <p><img src="../assets/img/logo-turbotax.png" alt="turbotax"></p>
          <p class="rating mr-0">
            <span>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
              <i class="icon-star"></i>
            </span> (456865 reviews)
          </p>
        </div>
        <div class="col-sm-3 text-right numbers">
          <span>Trust Score:</span> 8.7/10
        </div>
      </div>
    </div>
  </section>

  <nav class="mobile__nav" [ngClass]="{show: isMobileNavVisible}">
    <a class="logo"></a>
    <a href="javascript:;" class="exit" (click)="hideMobileNav()">&times;</a>
    <ul>
      <li class="enter"><a (click)="onOpenDialogButtonClicked(true)" class="btn btn-enter">Log in</a></li>
      <li><a (click)="onOpenDialogButtonClicked(false)" class="btn btn-enter">Create account</a></li>
      <li><a href="#">Clients</a></li>
      <li><a href="#">Partners</a></li>
    </ul>
  </nav>
</section>