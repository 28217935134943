import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompareValidatorDirective } from './directives/compare-validator.directive';
import { MouseWheelDirective } from './directives/mouse-wheel.directive';
import { EmployerIdentNumberDirective } from './directives/input-masks/employer-ident-number.directive';
import { FederalIdentNumberDirective } from './directives/input-masks/federal-ident-number.directive';
import { DragAndDropDirective } from './directives/drag-and-drop/drag-and-drop.directive';

@NgModule({
  declarations: [CompareValidatorDirective, MouseWheelDirective, EmployerIdentNumberDirective, FederalIdentNumberDirective, DragAndDropDirective],
  imports: [CommonModule],
  exports: [CompareValidatorDirective, MouseWheelDirective, EmployerIdentNumberDirective, FederalIdentNumberDirective, DragAndDropDirective]
})
export class DirectivesModule {}
